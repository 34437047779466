import { FacebookLogo, InstagramLogo, WhatsappLogo } from '@phosphor-icons/react';
import logo from '../../assets/logo/logo.webp'
import './styles.css'

function NavBar() {

  return (
    <nav className="navbar">
      <div className="logoNav">
        <a href="#home">
          <img src={logo} alt="Logo da Empresa"   />
        </a>
      </div>
      <ul className="menu-list">
        <li className="menu-item">
          <a href="#therapists-section">
             Terapeutas
          </a>
        </li>
        <li className="menu-item">
          <a href="#massages">
             Massagens
          </a>
        </li>
        <li className="menu-item">
          <a href="#place">
             Espaço
          </a>
        </li>
        <li className="menu-item">
          <a href="#section-aboutUs">
             Sobre Nós
          </a>
        </li>
        <li className="menu-item">
          <a href="#contact">
             Contato
          </a>
        </li>
      </ul>
      <div className="social-icons">
        <a href="https://wa.me/5585996429491?text=Gostaria+de+agendar+minha+experi%C3%AAncia" target="_blank" rel="noopener noreferrer">
          <WhatsappLogo size={32} weight="fill" color="#DDDDDD" />
        </a>
        <a href="https://www.instagram.com/espaco.maha/" target="_blank" rel="noopener noreferrer">
          <InstagramLogo size={32} weight="fill" color="#DDDDDD" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61554992760172&mibextid=YMEMSu" target="_blank" rel="noopener noreferrer">
          <FacebookLogo size={32} weight="fill" color="#DDDDDD" />
        </a>
      </div>
    </nav>
  );
}

export default NavBar;