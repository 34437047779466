import './styles.css'
import Foto1 from '../../assets/logo/logo-preta.webp'

function CardMassagem(props){
    return (
        <div className="card-section">
            <div className="card">
                <div className="flip-card">
                    <div className="flip-card__container">
                        <div className="card-front">
                            <div className="card-front__tp">
                                <div id="gradientMassage" />
                                <img src={Foto1} alt='Logo da Empresa' className='card-front__icon' />
                                <h2 className="card-front__heading">
                                    {props.titleFront}
                                </h2>
                            </div>
                            <div className="card-front__bt">
                                <p className="card-front__text-view card-front__text-view--city">
                                    Mais Informações
                                </p>
                            </div>
                        </div>
                    
                        <div className="card-back">
                            <img src={props.photo} alt="" className="image__container"/>
                        </div>
                    </div>
                </div>
                <div className="inside-page">
                    <div className="inside-page__container">
                        <h3 className="inside-page__heading inside-page__heading--city">
                            {props.title}
                        </h3>
                        <p className="inside-page__text">
                            {props.description}
                        </p>
                        <p className="inside-page__text">
                            {props.description2}
                        </p>

                        <a href="https://wa.me/5585996429491?text=Gostaria+de+agendar+minha+experi%C3%AAncia" target="_blank" rel="noopener noreferrer" className="inside-page__btn inside-page__btn--city">Agendar Massagem</a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardMassagem;
