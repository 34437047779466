import React from 'react';
import './styles.css'; 
import logoHome from '../../assets/logo/logoHome.webp';

function Header() {
  return (
    <section className="start-section" id="home">
        <div id="gradient" />

        <div className="content">
            <img src={logoHome} alt="Logo da Empresa" id="logo" />
            <p>
                Uma experiência única e prazerosa <br /> 
                para seu corpo e sua mente.
            </p>
            <a href="https://wa.me/5585996429491?text=Gostaria+de+agendar+minha+experi%C3%AAncia" target="_blank" rel="noopener noreferrer">
                <button>Agendar Massagem</button>
            </a>
        </div>
    </section>
  );
}

export default Header;
