import './style.css'
import Logo from '../../assets/logo/logo.webp'
function Title (props) {
    return (
        <div className='titulo'>
            <h1 className='textTitle'>{props.textTitle}</h1>
            <div className='divImage'>
                <img src={Logo} alt="" className="logoImagem"/>
            </div>
        </div>
    )
}

export default Title;