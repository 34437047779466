import React from 'react';
import logo from '../../assets/logo/logo.webp'
import './styles.css'; 

function Footer() {
  return (
    <footer>
      <img src={logo} alt="" />
      <span>
        ESPAÇO MAHA | CENTRO DE MASSAGENS ALTERNATIVAS LTDA CNPJ: 47.600.145/0001-97
      </span>
    </footer>
  );
}

export default Footer;
