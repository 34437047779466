import './App.css';   
import NavBar from './components/NavBar';
import Header from './sections/Header'
import Therapists from './sections/Therapists';
import Massage from './sections/Massages';
import Photos from './sections/Photos'
import AboutUs from './sections/AboutUs'
import Contact from './sections/Contact'
import Footer from './sections/Footer';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Header/>
      <Therapists />
      <Massage/>
      <Photos/>
      <AboutUs/>
      <Contact/>
      <Footer/>
  </div>
  );
}

export default App;
