import Title from "../../components/Title";
import phodo from "./../../assets/aboutUs/AboutUsTherapist.webp";

import { Circle } from "@phosphor-icons/react";
import "./styles.css"

function AboutUs() {
    const widthValue = window.innerWidth;

    return(
        <section id="section-aboutUs">
            <div id="aboutUs-filter" />

            <div id="aboutUs-content">
                <div>
                    <Title textTitle="Sobre nós" />
                    {
                        widthValue <= 800 ? (
                            <div id="phodo-AboutUs">
                                <img src={phodo} alt="" />
                            </div>
                        ) : ("")
                    }
                    <div id="aboutUs-info">
                        <p>
                            <Circle size={8} weight="fill" />
                            O Espaço Maha é um centro de massagens alternativas em Fortaleza com
                            um conceito de conexão e integração para quem busca explorar o máximo
                            do potencial da mente e do corpo através do toque.
                        </p> 
                        <p>
                            <Circle size={8} weight="fill" />
                            Nosso espaço é aconchegante, acolhedor e discreto. O espaço físico
                            de um modo geral busca proporcionar experiência desde a entrada 
                            com ambiente arborizado com vagas para melhor comodidade.
                        </p> 
                        <p>
                            <Circle size={8} weight="fill" />
                            Pensado para quem busca um momento de autoconhecimento, cuidado, e até 
                            mesmo descompressão, cada detalhe foi pensado e criado para uma experiência 
                            <strong> Maha!</strong>
                        </p>
                        <p>
                            <Circle size={8} weight="fill" />
                            A equipe é formada por profissionais éticas, atenciosas e com todo preparo 
                            necessário para atendimentos nas modalidades terapêuticas: tântricas, corporais, 
                            relaxantes e ministração de cursos para Homens e mulheres (necessário ser maior de 18 anos). 
                        </p> 
                    </div>
                </div>

                {
                    widthValue > 800 ? (
                        <div id="phodo-AboutUs">
                            <img src={phodo} alt="" />
                        </div>
                    ) : ("")
                }
                

            </div>
        </section>
    )
}

export default AboutUs;