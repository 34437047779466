import { useState, useEffect, useRef } from 'react';

import { motion, useAnimation, useInView } from 'framer-motion';
import Title from '../../components/Title';
import { v4 as uuidv4 } from "uuid";
import "./styles.css"
import therapistsInfo from './therapistsInfo';

function Therapists () {
    const carousel = useRef();
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, []);

    const controls = useAnimation();
    const ref = useRef(null);
    const inView = useInView(ref);

    useEffect(()=> {
        if (inView) {
            controls.start({ x: 0, opacity: 1 });
        }
    }, [controls, inView]);

    return (
        <section id="therapists-section" >
            <Title textTitle="Terapeutas" />
            <motion.div ref={carousel} id="carousel" whileTap={{ cursor: "grabbing"}}>
                <motion.div 
                    className="inner" 
                    drag="x"
                    dragConstraints={{ right: 0, left: -width}}
                    ref={ref}
                    initial={{ x: 200, opacity: 0 }}
                    animate={ controls }
                    transition={{ duration: 2 }}
                >
                    {
                        therapistsInfo.map((therapist, index) => 
                            <motion.div className="item" key={uuidv4()}>
                                <img src={therapist.phodo} alt={"Therapist " + therapist.name} />
                                <span>
                                    Terapeuta <strong> {therapist.name}</strong>

                                    <p>
                                        {therapist.description}
                                    </p>
                                </span>
                            </motion.div>
                        )
                    }
                </motion.div>
            </motion.div>
            
        </section>
    );
}

export default Therapists;
