import Title from "../../components/Title";
import './styles.css'


import image1 from "./../../assets/Espaço/foto1.webp"
import image2 from "./../../assets/Espaço/foto2.webp"
import image3 from "./../../assets/Espaço/foto3.webp"
import image4 from "./../../assets/Espaço/foto4.webp"
import image5 from "./../../assets/Espaço/foto5.webp"
import image6 from "./../../assets/Espaço/foto6.webp"
import image7 from "./../../assets/Espaço/foto7.webp"
import image8 from "./../../assets/Espaço/foto8.webp"
import image9 from "./../../assets/Espaço/foto9.webp"
import image10 from "./../../assets/Espaço/foto10.webp"
function Photos(){
    const teste = [ image1, image2, image3, image4, image5, image6, image7, image8, image9, image10 ];


    return(
        <section className="start-sectionPhotos" id="place" >
            <Title textTitle="Nosso Espaço"/>
            <div className="gallery">

                <div className="phodos-list">
                <div id="gradientPhotos" />
                    {
                        teste.map((phodo) => (
                            <div className="phodo">
                                <img src={phodo} alt="" />
                                <span>
                                    <p>
                                        
                                    </p>
                                </span>
                            </div>
                        ))
                    }
                </div>

            </div>
        </section>
    )
}

export default Photos;