import { FacebookLogo, InstagramLogo, WhatsappLogo } from '@phosphor-icons/react';
import Title from '../../components/Title';
import './styles.css'
function Contact (){
    return (
        <section className='section-contact' id='contact'>
            <div id="contact-filter" />

            <div className='contactContent'>
                <Title textTitle="Onde Estamos"/>
                <div className='contact-info'>
                    <div className="frame-location">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.314267896384!2d-38.481409425026214!3d-3.741546996232354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74788b5ccb7e1%3A0x684320170067441c!2sEspa%C3%A7o%20Maha!5e0!3m2!1spt-BR!2sbr!4v1701021194511!5m2!1spt-BR!2sbr"  title='Localização da Empresa'></iframe>
                    </div>
                    <div className="contactPanel">
                        <div id="gradientContact" />

                        <div className="address">
                            <h2>Localização</h2>
                            <p>
                                R. Joaquim Lima,917, Papicu, Fortaleza
                                <br />
                            </p>
                        </div>
                        <div className="contactUs">
                            <h3>Agendar Massagem</h3> 
                            
                                <div className="contacts">
                                    <a href="https://www.instagram.com/espaco.maha/" target="_blank" rel="noopener noreferrer">
                                        <InstagramLogo size={32} weight="fill" color="#DDDDDD" />
                                        @espaco.maha
                                    </a>
                                
                                    <a href="https://wa.me/5585996429491?text=Gostaria+de+agendar+minha+experi%C3%AAncia" target="_blank" rel="noopener noreferrer">
                                        <WhatsappLogo size={32} weight="fill" color="#DDDDDD" />
                                        (85) 99642-9491
                                    </a>
                                    
                                    <a href="https://www.facebook.com/profile.php?id=61554992760172&mibextid=YMEMSu" target="_blank" rel="noopener noreferrer">
                                        <FacebookLogo size={32} weight="fill" color="#DDDDDD" />
                                        Espaço Maha Oficial
                                    </a>
                                </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default Contact;