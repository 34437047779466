import therapistJulia01 from "./../../assets/Terapeutas/Julia01.webp"
import therapistJulia02 from "./../../assets/Terapeutas/Julia02.webp"
import therapistSabrina02 from "./../../assets/Terapeutas/Sabrina01.webp"
import therapistSabrina01 from "./../../assets/Terapeutas/Sabrina02.webp"
import therapistLu01 from "./../../assets/Terapeutas/Lu01.webp"
import therapistLu02 from "./../../assets/Terapeutas/Lu02.webp"
import therapistBel01 from "./../../assets/Terapeutas/Bel01.webp"
import therapistBel02 from "./../../assets/Terapeutas/Bel02.webp"
import therapistPaloma01 from "./../../assets/Terapeutas/Paloma01_1.webp"
import therapistPaloma02 from "./../../assets/Terapeutas/Paloma02_1.webp"


const therapistsInfo = [
    
    {
        "name": "Julia",
        "description": "Conhecida pela técnica promovida através do poder da intenção, ressaltando a consciência por meio da atenção e comprometimento com a experiência. Profissional capacitada e preparada para um atendimento de excelência, possui habilidades práticas e lúdicas sobre as terapias. Especialista nas técnicas tântrica e corpo a corpo, também atua no campo de ministração de cursos.",
        "phodo": therapistJulia01
    },
    {
        "name": "Brisa Bandeira",
        "description": "Profissional atenciosa e gentil, dedicada com a constante evolução de conhecimento para proporcionar vivências ayurvédica com as melhores técnicas, promovendo experiências únicas de relaxamento e auto conhecimento. ",
        "phodo": therapistSabrina01
    },
    {
        "name": "Luana Amora",
        "description": "Estou aqui para despertar seu potencial interno, guiando-o para uma manifestação intensa e respeitosa no externo. Minha abordagem consiste na compreensão profunda do corpo, mente e espírito, através do diálogo, acolhimento e conexão com seu templo sagrado, para assim promover um equilíbrio holístico. Realizo cada toque como uma expressão dedicada ao seu bem-estar, que nutre sua essência com cuidado, a fim de tornar o seu momento em uma experiência transformadora e de autoconhecimento.",
        "phodo": therapistLu01
    },
    {
        "name": "Isa Santos",
        "description": "Prazer sou Isa Santos, Massoterapeuta e terapeuta corporal especialista em Tantra e Yoga massagem Ayurvédica, formada no centro holístico de Piracanga/BA. Estou comprometida em auxiliar em seus processos de cura por meio das ferramentas que adquiri para servir através da terapia corporal. Meu propósito é contribuir para uma harmonia física, emocional e energética e criar um espaço de liberdade onde você pode se expressar com autenticidade. Sei que a jornada de autoconhecimento é extensa e desafiadora, será uma honra te acompanhar nela!",
        "phodo": therapistBel01
    },
    {
        "name": "Paloma",
        "description": "Terapeuta holística e massoterapeuta com mais de 3 anos de experiência, incorporo o poder transformador do Reiki em seus atendimentos. Busco sempre promover o bem-estar físico e energético, guiando os clientes a saírem revitalizados tanto no corpo quanto no espírito.",
        "phodo": therapistPaloma01
    },
    {
        "name": "Julia",
        "description": "",
        "phodo": therapistJulia02
    },
    {
        "name": "Brisa Bandeira",
        "description": "",
        "phodo": therapistSabrina02
    },
    {
        "name": "Luana Amora",
        "description": "",
        "phodo": therapistLu02
    },
    {
        "name": "Isa Santos",
        "description": "",
        "phodo": therapistBel02
    },
    {
        "name": "Paloma",
        "description": "",
        "phodo": therapistPaloma02
    }
]

export default therapistsInfo;