import Card from '../../components/CardMassage'
import Title from '../../components/Title'
import Photo from '../../assets/Massagens/ImagemInternaCard.webp'
import './styles.css'
function Massage (props){


    const textTantrica1 = "Experiência holística que promove relaxamento, prazer e conexão profunda com o corpo. Essa massagem vai muito além do toque físico com capacidade de liberar bloqueios energéticos e despertar grandiosamente a sensibilidade corporal. São promovidos diversos benefícios físicos e emocionais como; Autoconhecimento, auxílio contra ansiedades, curas internas, melhora em particularidades íntimas, avanço na disposição física e experiência orgástica.";
    const textTantrica2 = "Ao início, são aplicados toques firmes sempre no sentido do fluxo sanguíneo, na intenção de liberar nódulos e aliviar tensões e em seguida é usada a técnica sensitive; toques leves despertando o sensorial, evidenciando a capacidade de proporcionar sentimentos únicos e sutis. São usadas as manobras LINGAM(Técnica Masculina) ou YONI(Técnica Feminina), que consistem em manobras no órgão genital capazes de proporcionar uma enorme sensação de prazer e relaxamento";
    const textCorpoACorpo1= "A potência do CORPO: Considerada a mais intensa das massagens, esta é uma terapia naturista, realizada através do deslizamento corporal, com capacidade de expandir a energia vital. Nessa experiência são aplicadas técnicas com partes macias do corpo da terapeuta, tais como; coxas, abdômen e peito, utilizando o peso do corpo, o que intensifica a sensação de relaxamento e prazer. Sutilmente despertando pontos erógenos e elevando os níveis orgásticos.";
    const textCorpoACorpo2 = "As técnicas dessa massagem são capazes de auxiliar fortemente em disfunções sexuais, além de gerar mais poder ao autoconhecimento e avanço na disposição física. São usadas as manobras LINGAM(Técnica Masculina) ou YONI(Técnica Feminina), que consistem em manobras no órgão genital capazes de proporcionar uma enorme sensação de prazer e relaxamento.";
    const textSesation1 = "O despertar das SENSAÇÕES: Com a enorme capacidade de trazer uma atmosfera única, essa experiência conta com o estímulo dos sentidos, tornando grandes e intensos todos os sentimentos do receptor no ato da massagem. Visão, audição, paladar, olfato e tato são trabalhados ao início. Buscando o aflorar destes conseguimos permear sensações unânimes, seguido de toques leves despertando o sensorial, com a sensitive.";
    const textSesation2 = "Deste modo é possível receber de forma mais intensa a técnica corpo a corpo aplicada com as partes macias do corpo da terapeuta; coxas, abdômen e peito. Sutilmente atingindo pontos erógenos e elevando os níveis orgásticos. São usadas as manobras LINGAM(Técnica Masculina) ou YONI(Técnica Feminina), que consistem em manobras no órgão genital capazes de proporcionar uma enorme sensação de prazer e relaxamento.";
   

    return(
        
        <section  id='massages' className='start-sectionMassage'>
            
            <div id="massages-filter" />
                <div className='content-massage'>
                    <Title textTitle={"Massagens"}/>
                    <div className='cardsMassage'>
                        <Card 
                        photo={Photo}

                        titleFront={"| TANTRICA |"}
                        title="O poder da TANTRICA"
                        description={textTantrica1}
                        description2={textTantrica2}

                        />
                    </div>
                    <div className='cardsMassage'>
                        <Card 
                        photo={Photo}

                        titleFront={"| CORPO A CORPO |"}
                        title="O poder do CORPO"
                        description={textCorpoACorpo1}
                        description2={textCorpoACorpo2}

                        />
                    </div>
                    <div className='cardsMassage'>
                        <Card 
                        photo={Photo}

                        titleFront={"| SENSATION |"}
                        title="O despertar das SENSAÇÕES"
                        description={textSesation1}
                        description2={textSesation2}
                        />
                    </div>
            </div>
       
        </section>
        
    )
}

export default Massage;
